import Header from "../header/header";
import { about } from "./data";
import { joinus } from "./data";
import img2 from "../../static/img2.jpg";
import Choose from "./choose";
const About = () => {
  return (
    <>
      <Header />
      <div className="container aboutcontainer">
        <div className="text-center">
          <h4>About Us</h4>
          <div>{about}</div>
        </div>
        <div className="mt-4 d-flex justify-content-around">
          <div>
            <h5>Join us in this motivational journey where,</h5>
            <ul style={{ listStyleType: "none" }}>
              {joinus.map((item, id) => {
                return (
                  <li>
                    <span className="bi mx-2 bi-check2-square"></span>
                    {item}
                  </li>
                );
              })}
              <p>converge to create a future that surpasses expectations</p>
            </ul>
          </div>
          <img width={500} height={250} src={img2} alt="tech img"></img>
        </div>
        <div className="mt-4 ">
          <Choose />
        </div>
      </div>
    </>
  );
};

export default About;

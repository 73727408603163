import classes from "./header.module.css";
import { useNavigate } from "react-router-dom";
import logo from "../../static/newlogo.png";
const Header = () => {
  const navigate = useNavigate();
  const handleNav = (targetid) => {
    navigate(`/${targetid}`);
  };
  return (
    <>
      <header className={` ${classes.headercontainer} `}>
        <div>
          <img
            onClick={() => handleNav("")}
            src={logo}
            width={50}
            height={50}
            alt="Logo"
          ></img>
        </div>
        <div className={classes.linkswrap}>
          <p
            id="internships"
            onClick={() => handleNav("internships")}
            className="mx-2"
          >
            Internships
          </p>
          <p id="about" onClick={() => handleNav("about")} className="mx-2">
            AboutUs
          </p>
          <p id="contact" onClick={() => handleNav("contact")} className="mx-2">
            contactUs
          </p>
        </div>
        <div>
          <a
            href="https://forms.gle/F8jzBSAKMANU9RT27
"
            className="btn btn-outline-primary"
          >
            Apply
          </a>
        </div>
      </header>
    </>
  );
};

export default Header;

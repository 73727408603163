import classes from "./contact.module.css";
import Header from "../header/header";

const Contact = () => {
  return (
    <>
      <Header />
      <div className="container">
        <h1>Feel free to contact us</h1>
        <div className="m-3">
          <a
            className={classes.link}
            href="mailto:codefordigitalindai@gmail.com"
          >
            <span className="bi bi-envelope"></span>Gmail
          </a>
        </div>
        <div className="m-3">
          <a
            className={classes.link}
            href="https://www.instagram.com/invites/contact/?i=1j11sc3q0fo6h&utm_content=t1o9s6g"
          >
            <span className="bi bi-instagram"></span>Instagram
          </a>
        </div>
        <div className="m-3">
          <a
            className={classes.link}
            href="https://chat.whatsapp.com/LCvZ7MxllLq38l5CQM9N13"
          >
            <span className="bi bi-whatsapp"></span>whatsapp
          </a>
        </div>
        <div className="m-3">
          <a
            className={classes.link}
            href="https://www.facebook.com/CodeForDigitalIndia?mibextid=ZbWKwL"
          >
            <span className="bi bi-facebook"></span>Facebook
          </a>
        </div>
        <div className="m-3">
          <a className={classes.link} href="tel:+9494935942">
            <span className="bi bi-phone"></span>Phone
          </a>
        </div>
        <div className="m-3">
          <a className={classes.link} href="https://t.me/CodeForDigitallIndia">
            <span className="bi bi-telegram"></span>Telegram
          </a>
        </div>
        <div className="m-3">
          <a
            className={classes.link}
            href="https://www.linkedin.com/company/codefordigitalindia/"
          >
            <span className="bi bi-linkedin"></span>LinkedIn
          </a>
        </div>
      </div>
    </>
  );
};

export default Contact;

import Card from "./internship-card";
import Header from "../header/header";

import python from "../../static/python.jpg";
import andriod from "../../static/andriod.jpg";
import datascience from "../../static/datascience.jpg";
import graphics from "../../static/graphics.jpg";
import java from "../../static/java.jpg";
import web from "../../static/web.jpg";

const Internships = () => {
  return (
    <>
      <Header />
      <div className={`mt-4`}>
        <div className="m-4 d-flex justify-content-evenly">
          <Card img={web} name="Web Development" />
          <Card img={andriod} name="Andriod Development" />
          <Card img={python} name="Python Development" />
        </div>
        <div className="m-4 d-flex justify-content-evenly">
          <Card img={datascience} name="Data Science" />
          <Card img={java} name="Java Development" />
          <Card img={graphics} name="Graphic Designing" />
        </div>
        <div className="text-center">
          <button className="btn btn-outline-dark ">Get Started </button>
          <p className="text-center">
            Together, let's build not just software, but a legacy of excellence
            and inspiration.
          </p>
        </div>
      </div>
    </>
  );
};

export default Internships;

import { why } from "./data";
import { benifits } from "./data";
import { essence } from "./data";
const Choose = () => {
  return (
    <div>
      <div className="text-center">
        <h4>Why Choose Us?</h4>
        <p style={{ fontWeight: "bold" }}>{why}</p>
      </div>
      <div className="d-flex justify-content-evenly">
        <ul style={{ listStyle: "none" }}>
          {benifits.map((item) => {
            return (
              <li>
                <span className="mx-3 bi bi-check"></span>
                {item}
              </li>
            );
          })}
        </ul>
        <div>
          <h5>Essence of Code For Digital India</h5>
          <ul style={{ listStyle: "none" }}>
            {essence.map((item) => {
              return (
                <li>
                  <span className="mx-3 bi bi-check"></span>
                  {item}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Choose;

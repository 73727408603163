export const about =
  "At our organization, we specialize in crafting tailor-made software solutions, transforming the digital aspirations of our clients into tangible reality. Our collaborative approach involves closely engaging with users throughout the development journey, ensuring continuous alignment with the ultimate objective. Our dedication is unwavering as we strive to deliver outstanding software experiences for each and every client we serve. Experience our commitment to delivering reliability and efficiency, driven by a team of top-tier engineers and meticulously honed software development processes. Our core values are rooted in the belief in leadership, where we strive to guide technology towards crafting a superior future. Integrity is the bedrock of our principles, prompting us to uphold the truth and authenticity in all our actions. Every commitment we make is met with unwavering accountability, ensuring that our promises translate into tangible results";

export const joinus = [
  "imagination",
  "engineering",
  "modernization",
  "and management",
];

export const why =
  "Code For Digital India initiated this internship program under the inspiration and guidance of our CEO. Geared towards providing students with genuine industry-level exposure, the internship aims to equip participants with practical insights. This unique opportunity is not limited to students; experienced professionals are also welcome to enroll in this program and enrich their skill set.";

export const benifits = [
  "Get Offer Letter",
  "Internship Completion Certificate",
  "Letter Of Recommendation",
  "Earn Swags and Rewards",
];

export const essence = [
  "Embrace Client Success",
  "Think Globally, Act Responsibly",
  "Foster Respect for Every Individual",
  "Unwavering Integrity in Every Endeavor",
];

import Header from "../header/header";
import classes from "./landing.module.css";

import logo from "../../static/logo.jpg";
import Footer from "../footer/footer";
const Landing = () => {
  return (
    <>
      <div className="">
        <Header />
        <div className={classes.aictecontainer}>
          <h1 className={classes.aicteheading}>WELCOME TO</h1>

          <img src={logo} alt="aicte" className={classes.aicteimg}></img>
        </div>
        <div className={`container ${classes.landingtext} text-center`}>
          <h4>Approved by AICTE & Registered With MSME</h4>
          <p>
            Join our exceptional team and gain invaluable hands-on experience,
            all while being mentored by industry veterans. You'll tackle
            meaningful real-world projects, infuse your creativity, and thrive
            in a collaborative, forward-thinking atmosphere
          </p>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Landing;

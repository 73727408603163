import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Landing from "./components/landing/landing";
import Internships from "./components/internships/internships";
import About from "./components/about/about";
import Contact from "./components/contact/contact";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={"/"} element={<Landing />}></Route>
        <Route path={"/internships"} element={<Internships />}></Route>
        <Route path={"/about"} element={<About />}></Route>
        <Route path={"/contact"} element={<Contact />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import classes from "./internship.module.css";

const Card = (props) => {
  const styles = {
    backgroundImage: `url(${props.img})`,
    width: "100%",
    height: "80%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  };
  return (
    <div className={classes.card}>
      <div className="d-flex justify-content-between">
        <h6 style={{ color: "black", fontWeight: "bold" }}>{props.name}</h6>
        <a
          href="https://forms.gle/F8jzBSAKMANU9RT27
"
          className="btn btn-outline-info "
        >
          ApplyNow
        </a>
      </div>
      <div style={styles} className={`text-center `}></div>
    </div>
  );
};

export default Card;

const Footer = () => {
  return (
    <footer className="text-center ">
      <div className="d-flex justify-content-evenly">
        <p>Terms&Conditions</p>
        <p>Privacy&Policy</p>
      </div>
      <p className="text-muted mx-2">
        &copy; CopyRights 2023, All rights reserved for CFDI
      </p>
      <p className="text-muted mx-2"></p>
    </footer>
  );
};

export default Footer;
